<template>
	<div class="bg-Hight">
		<div class="content">
			<div class="title">
				基本信息
			</div>
			<ul class="list config">
				<li>
					<div class="name">头像</div>
					<div class="input">
						<div class="img">
							<img v-if="ruleForm.avatar" :src="ruleForm.avatar" />
							<img v-else src="@/assets/img/tx.png" />
							<p v-if="ruleForm.avatar" @click="dele">删除</p>
						</div>
						<el-upload class="fupload" list-type="picture-card" :on-success="handleAvatarSuccess"
							:headers="Headers" :action="ActionUrl">
							<i class="icon iconfont icon-shangchuan"></i>
						</el-upload>
					</div>
				</li>
				<li class="ftime">
					<div class="name">用户名<span>*</span></div>
					<div class="input">
						<el-input disabled clearable type="text" v-model="ruleForm.account" placeholder="请输入用户名">
						</el-input>
					</div>
				</li>

				<li class="ftime">
					<div class="name">姓名<span></span></div>
					<div class="input">
						<el-input clearable type="text" v-model="ruleForm.real_name" placeholder="请输入姓名"></el-input>
					</div>
				</li>

				<li class="ftime">
					<div class="name">手机号</div>
					<div class="input">
						<el-input clearable type="text" v-model="ruleForm.phone" placeholder="请输入手机号">
						</el-input>
					</div>
				</li>
				<li class="ftime">
					<div class="name">密码</div>
					<div class="input">
						<el-input clearable type="text" v-model="ruleForm.password" placeholder="请输密码">
						</el-input>
					</div>
				</li>
				<li>
					<div class="name"></div>
					<div class="input">
						<el-button @click="submitForm" class="el-white" clearable color="#5473E8" style="color: white"
							size="small">保存修改
						</el-button>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		request,
		api
	} from "@/api/user";
	import {
		ElMessage
	} from 'element-plus'
	export default {
		data() {
			return {
				Headers: {
					authorization: '',
					appid: '',
					nonce: '',
					timestamp: '',
					sign: ''
				},
				ActionUrl: '',
				ruleForm: {
					account: '',
					avatar: '',
					real_name: '',
					phone: '',
					password: '',
					id: ''
				}
			}
		},
		created() {
			console.log(this.$store.state.userinfo, "用户信息")
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
			this.getinfo()
		},
		methods: {
			// 保存
			submitForm() {
				this.ruleForm.id = this.$store.state.userinfo.id
				if (this.ruleForm.account === '') {
					ElMessage({
						message: '请输入用户名',
						type: 'warning',
					})
				} else {
					this.$http.post(api.edit, this.ruleForm)
						.then((res) => {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$EventBus.emit('RefreshUser')
						})
				}

			},
			// 删除图片
			dele() {
				this.ruleForm.avatar = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.read, {
						id: this.$store.state.userinfo.id
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm = res.data
						}
					})

			},
		}
	}
</script>

<style lang="scss" scoped>
	.list {
		padding-left: 60px;

		li {
			width: 100%;
			font-size: 14px;
			display: flex;
			margin-bottom: 18px;

			.name {
				padding: 0 12px 0 0;
				line-height: 32px;
				width: 80px;

				span {
					color: red;
					margin-left: 8px;
				}
			}

			.input {
				position: relative;

				p {
					font-size: 12px;
					cursor: pointer;
					width: 80px;
					position: absolute;
					bottom: 0;
					height: 20px;
					line-height: 20px;
					left: 0;
					text-align: center;
					background: rgba(0, 0, 0, 0.5);
					color: #FFFFFF;
				}

				i {
					font-size: 45px;
				}

				.fupload {
					display: inline-block;
				}

				.img {
					float: left;
					width: 80px;
					margin-right: 10px;
					display: flex;
					padding: 10px;
					align-items: center;
					justify-content: center;
					height: 80px;
					border: 1px solid #dcdde0;
					border-radius: 4px;
					overflow: hidden;

					img {
						width: 100%;
					}
				}
			}
		}
	}

	.content {
		padding: 20px;

		.title {
			color: #000;
			margin-bottom: 30px;
			font-size: 15px;
		}
	}
</style>
